import React from "react";

const Home = React.lazy(() =>
  import("./pages/Home/index")
);  
const Service1 = React.lazy(() => import("./pages/Service1/index"));
const Service2 = React.lazy(() => import("./pages/Service2/index"));  
const Service3 = React.lazy(() => import("./pages/Service3/index"));
const Service4 = React.lazy(() => import("./pages/Service4/index"));
const Service5 = React.lazy(() => import("./pages/Service5/index"));   
const Service6 = React.lazy(() => import("./pages/Service6/index"));
const Service7 = React.lazy(() => import("./pages/Service7/index"));
const Service8 = React.lazy(() => import("./pages/Service8/index"));
  
//ContactS
const PageContact = React.lazy(() =>
  import("./pages/Pages/Contact/PageContact")
);  


const routes = [
  //routes without Layout
  // Landings
  { path: "/", component: Home,  isTopbarDark: true  },
  { path: "/service1", component: Service1 },
  { path: "/service2", component: Service2 },
  { path: "/service3", component: Service3 },
  { path: "/service4", component: Service4, isTopbarDark: true },
  { path: "/service5", component: Service5,isTopbarDark: true },
  { path: "/service6", component: Service6,isTopbarDark: true },
  { path: "/service7", component: Service7 },
  { path: "/service8", component: Service8, isTopbarDark: true },
   //Page Contact
   { path: "/page-contact-one", component: PageContact, isTopbarDark: true },
];

export default routes;
  